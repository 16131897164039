"use strict";
var mobile = false,
    desktop = true,
    windowScrollTop = 0,
    stopHeaderChangesOnScroll = false,
    windowWidth = 1024,
    constWindowScrollTop,
    locoScrollHeader = false;

$(document).ready(function () {

    mobile = isMobile();
    desktop = (isMobile() || isTablet()) ? false : true;
    if (desktop) {
        $('html').addClass('desktop');
    }
    else {
        $('html').addClass('device');
    }

    windowWidth = $(window).width();

    if(validBrowser) {
        headerFunctions();
        spoilerFunctions();
        tabsFunctions();
        tipsFunctions();
        socialAsideFunctions();
        initSocialShare();
        newsPreviewFunctions();
        langFunctions();

        $('.menu > li > a').on('click', function (e) {
            if(!desktop) {
                let ths = $(this);
                let submenu = ths.next('ul');
                if (submenu.length) {
                    if (!ths.hasClass('activated')) {
                        ths.addClass('activated');
                        if (windowWidth < 1024) {
                            submenu.slideDown(300);
                        }
                    } else {
                        ths.removeClass('activated');
                        if (windowWidth < 1024) {
                            submenu.slideUp(300);
                        }
                    }

                    e.preventDefault();
                }
            }
        });

    	if(isTablet() && windowWidth >= 1024) {
    		$(document).on('click', function (e) {
    			if(!$(e.target.closest('.menu')).length) {
    				$('.menu > li > a').removeClass('activated');
    			}
    		});
    	}

        $('.main-slider').slick({
            infinite: true,
            dots: true,
            arrows: true,
            fade: true,
            autoplaySpeed: 5000,
            autoplay: true,
            prevArrow: '<button type="button" class="slick-prev"><svg class="icon"><use xlink:href="#arrow-left"></use></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg class="icon"><use xlink:href="#arrow-right"></use></svg></button>',
        });
        $('.news-slider').slick({
            infinite: true,
            dots: true,
            arrows: true,
            fade: false,
            prevArrow: '<button type="button" class="slick-prev"><svg class="icon"><use xlink:href="#arrow-left"></use></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg class="icon"><use xlink:href="#arrow-right"></use></svg></button>',
            customPaging: function (slick, index) {
                let image = $(slick.$slides[index]).find('.slider__img').attr('src');
                return '<button><div class="slide-preview"><img src="' + image + '" alt="" /></div></button>'
            }
        });

        if($('.slider-default .slide-one').length > 1) {
            $('.slider-default').slick({
                infinite: false,
                dots: true,
                arrows: true,
                fade: false,
                variableWidth: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                prevArrow: '<button type="button" class="slick-prev"><svg class="icon"><use xlink:href="#arrow-left"></use></svg></button>',
                nextArrow: '<button type="button" class="slick-next"><svg class="icon"><use xlink:href="#arrow-right"></use></svg></button>',
                responsive: [
                    {
                        breakpoint: 1536,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                ]
            });
            $('.slider-default').on('init', function(slick){
                console.log(slick);
            });
            $('.slider-default').on('reInit', function(slick){
                console.log(slick);
            });
        }
        $('[data-fancybox]').fancybox({
            hideScrollbar: false,
            beforeShow: function (instance, current) {
                disableScroll();
            },
            afterClose: function (instance, current) {
                enableScroll();
            },
        });

        visitFunctions();

        AOS.init();

        // $('.js__show-all').on('click', function (e) {
        //     let ths = $(this);
        //     ths.addClass('disabled');
        //     ths.find('.bar-block').addClass('visible');

        //     let block = ths.closest('.list-more');
        //     loadMoreNews(block, ths);
        //     e.preventDefault();
        // });
        $(".countdown").TimeCircles({
            animation_interval: "smooth",
            "circle_bg_color": "rgba(158,164,174,.7)",
            time: {
                Days: {color: "#FFC83C"},
                Hours: {color: "#FFC83C"},
                Minutes: {color: "#FFC83C"},
                Seconds: {color: "#FFC83C"}
            },
            "bg_width": 0.1,
            "fg_width": 0.03,
            count_past_zero: false
        });
        $(".countdown").TimeCircles().addListener(function(unit, value, total){
            if(total <= 0) {
                $('.main-slider').slick('slickRemove', $('.main-slider .slide-one').index($(".countdown").closest('.slide-one')));
            }
        });

        $('.news-paginator').each(function(){
            $(this).find('.js-hoverable').on('mouseenter', function(){
                $(this).addClass('hovered');
            });
            $(this).find('.js-hoverable').on('mouseleave', function(){
                $(this).removeClass('hovered');
            });
        });
    }
});

$(window).on('resize', function () {
    windowWidth = $(window).width();
});

// Your functions here
function fun(params) {
    console.log('fun says: ' + params);
}

// Let's write on ES6!
const es6Func = (param1, param2 = 'ES6') => {
    let words = param1 + ' — ' + param2;
    return `This is ${param1} and ${param2}, together: ${words}`;
};


const headerFunctions = () => {
    let header = $('.header');

    function repeatOften() {
        if(!locoScrollHeader) {
            setHeadeModeOnScroll();
            requestAnimationFrame(repeatOften);
        }
    }

    requestAnimationFrame(repeatOften);

    function setHeadeModeOnScroll() {
        windowScrollTop = $(document).scrollTop();
        if (windowScrollTop > 50) {
            header.addClass('fixed-mode');
        } else {
            header.removeClass('fixed-mode');
        }
    }

    $('.header-toggle').on('click', function (e) {
        let ths = $(this);
        let headerMenu = $('.header-content');
        headerMenu.addClass('visible');

        ths.addClass('activated');
        e.preventDefault();
    });
    $('.close-menu').on('click', function (e) {
        let headerToglle = $('.header-toggle');
        let headerMenu = $('.header-content');
        headerToglle.removeClass('activated');
        headerMenu.removeClass('visible');
        e.preventDefault();
    });
    $('.top-toggle').on('click', function (e) {
        let ths = $(this);
        let headerTop = $('.header-top');

        if (!ths.hasClass('activated')) {
            ths.addClass('activated');
            headerTop.addClass('visible');
        } else {
            ths.removeClass('activated');
            headerTop.removeClass('visible')
        }
        e.preventDefault();
    });
};

const newsPreviewFunctions = () => {
    $('.news-one-video, .news-one-sm-video').on('mouseenter', function () {
        let ths = $(this);
        let videoImg = ths.find('.webp-img');
        let imgSrc = videoImg.data('src');

        if (!videoImg.hasClass('loaded')) {
            ths.find('.bar-block').addClass('visible');
            videoImg.attr('src', imgSrc);
            videoImg.on('load', function (e) {
                ths.addClass('loaded');
            });
        }
    });
    $('.news-one-video, .news-one-sm-video').on('mouseleave', function () {
        let ths = $(this);
        let videoImg = ths.find('.webp-img');

        if (!videoImg.hasClass('loaded')) {
            ths.find('.bar-block').removeClass('visible');
        }
    });
};

const visitFunctions = () => {
    function hideIntroBanner() {
        let banner = $('.visit-banner');
        if (banner.hasClass('visible')) {
            banner.removeClass('visible');
            if($('.visit-block').length && $('.visit-block').hasClass('js-timer')) {
                $('.js-timer').removeClass('.js-timer');
                path.setAttribute('d', arc(21, 21, 19.5, 0, 0) + ' z');
                clearInterval(running);
                count = 0;
                $('.main-slider').slick('slickPlay');
            }
            enableScroll();
        }
    }

    $('.visit-toggle').on('click', function (e) {
        let ths = $(this);
        let banner = ths.closest('.visit-block').find('.visit-banner');

        if (!banner.hasClass('visible')) {
            disableScroll();
            banner.addClass('visible');
            $('.main-slider').slick('slickPause');
        } else {
            hideIntroBanner();
        }
        e.preventDefault();
    });
    $('.visit-banner__close').on('click', function(e) {
        let ths = $(this);
        let banner = ths.closest('.visit-banner');

        hideIntroBanner();
        e.preventDefault();
    });
    if($('.visit-block').length && $('.visit-block').hasClass('js-timer')) {
        $('.main-slider').slick('slickPause');
        disableScroll();
        var _ = function(s) { return document.querySelector(s); }, path = _('.fill-circle');

        var arc = function(x, y, r, s, f, rotate) {
        	// Parameters: x-axis center, y-axis center, radius, start degrees, finish degrees, rotation in degrees (optional)
        	function coords(degrees) {
        		var radians = (degrees - (90 - (rotate || 0))) * Math.PI / 180.0;
        		return {
        			x: x + (r * Math.cos(radians)),
        			y: y + (r * Math.sin(radians))
        		};
        	};
        	var start = coords(s), finish = coords(f);
        	var d = [
        		'M', start.x, start.y,
        		'A', r, r, 0, ~~(f - s > 180), 1, finish.x, finish.y
        	].join(' ');
        	return d;
        }; // end arc()

        // Animation
        var count = 359;
        var runIt = function() {
          if (count > 0) {
            count -= step;
            path.setAttribute('d', arc(21, 21, 19.5, 0, count));
          } else {
            path.setAttribute('d', arc(21, 21, 19.5, 0, 0) + ' z');
            clearInterval(running);
            count = 0;
            hideIntroBanner();
          }
        };
        var fps = 20;
        var step = 360 / (parseInt($('.js-timer').data('timer')) * 1000 / fps);
        var running = setInterval(function() { runIt() }, fps); // Run on load

        runIt();
    }
};

const socialAsideFunctions = () => {
    let aside = $('.social-stick');
    let socialBlock = $('.social-block');


    if (aside.length && socialBlock.length) {
        let socialBlockOffsetTop = socialBlock.offset().top;
        let windowHeight = $(window).height();

        function repeatOften() {
            setSocialAsideModeOnScroll();
            requestAnimationFrame(repeatOften);
        }

        requestAnimationFrame(repeatOften);

        function setSocialAsideModeOnScroll() {
            windowScrollTop = $(document).scrollTop();
            socialBlockOffsetTop = socialBlock.offset().top;
            if (windowScrollTop + windowHeight > socialBlockOffsetTop) {
                aside.addClass('hide');
            } else {
                aside.removeClass('hide');
            }
        }

        $(window).on('.resize', function () {
            socialBlockOffsetTop = socialBlock.offset().top;
            windowHeight = $(window).height();
        });
    }


};

const spoilerFunctions = () => {
    $('.spoiler-link').on('click', function (e) {
        let ths = $(this);
        let thsWrap = ths.closest('.spoiler');
        let thsContent = thsWrap.find('.spoiler-content');

        if (!ths.hasClass('active')) {
            ths.addClass('active');
            thsContent.slideDown(400);
        } else {
            ths.removeClass('active');
            thsContent.slideUp(400);
        }
        e.preventDefault();
    });

    $('.spoiler-title').on('click', function(e) {
        let ths = $(this);
        let thsWrap = ths.closest('.spoiler');
        let thsContent = thsWrap.find('.spoiler-content');

        //console.log(ths.text().trim());

        if (!ths.hasClass('active')) {
            ths.addClass('active');
            ths.data('text', ths.text().trim());
            ths.text('Скрыть');
            thsContent.slideDown(400);
        } else {
            ths.removeClass('active');
            ths.text(ths.data('text'));
            thsContent.slideUp(400);
        }
        e.preventDefault();
    });
};

const tabsFunctions = () => {
    const tabsResize = () => {
        $('.tabs_block').each(function () {
            let tabs = $(this);
            let mainList = tabs.find('.tabs_names');
            let dropList = tabs.find('.th__drop');
            let moreArea = tabs.find('.th__more');
            let dmoreAreaOffsetLeft = moreArea.offset().left;
            let hasHiddenTabs = false;

            mainList.find('li').removeClass('hide');
            mainList.find('.tabs_name').removeClass('hide');
            dropList.find('li').removeClass('hide');

            mainList.find('.tabs_name').each(function () {
                let ths = $(this);
                let thsOffsetLeft = ths.offset().left;
                let thsWidth = ths.width();
                let dataTab = ths.find('a').data('tab');
                let index = mainList.find('.tabs_name').index(ths);

                if (dmoreAreaOffsetLeft < thsOffsetLeft + thsWidth) {
                    hasHiddenTabs = true;
                    ths.addClass('hide');
                } else {
                    dropList.find('a').eq(index).parent('li').addClass('hide');
                }

                if(!hasHiddenTabs) {
                    moreArea.hide();
                }
                else {
                    moreArea.show();
                }
            });
        });
    };

    const tabsInit = () => {
        $('.tabs_block').each(function () {
            let tabs = $(this);
            let mainList = tabs.find('.tabs_names');
            $(mainList).addClass('th__list');
            let listItems = tabs.find('.tabs_name');

            $(mainList).wrap("<div class='tabs__head'></div>");

            var div = document.createElement('div');
            div.className = "th__more";
            var a = document.createElement('a');
            a.href = "javascript:void(0)";
            a.className = "tabs__toggle";
            var adiv = document.createElement('div');
            adiv.className = "toggle-icon open-icon";
            adiv.innerHTML = "<svg class='icon' id='dots-icon' viewBox='0 0 500 500'><path d='M500,292c-2-0.2-3.9-0.5-5.9-0.5c-23,0-45.9,0-68.9,0c-2,0-3.9,0-6,0c0-27.9,0-55.1,0-83c2.1,0,4.1,0,6,0c23,0,45.9,0,68.9,0c2,0,3.9-0.3,5.9-0.5C500,236,500,264,500,292z'></path><path d='M0,208c2,0.2,3.9,0.5,5.9,0.5c22.6,0,45.3,0,67.9,0c2,0,3.9,0,6.1,0c0,27.9,0,55.1,0,83c-2.2,0-4.1,0-6.1,0c-22.6,0-45.3,0-67.9,0c-2,0-3.9,0.3-5.9,0.5C0,264,0,236,0,208z'></path><path d='M290.3,208.9c0,27.6,0,54.7,0,82.2c-26.7,0-53.2,0-80,0c0-27.3,0-54.6,0-82.2C236.8,208.9,263.3,208.9,290.3,208.9z'></path></svg>";
            var adiv2 = document.createElement('div');
            adiv2.className = "toggle-icon close-icon";
            adiv2.innerHTML = "<svg class='icon' id='dots-icon' viewBox='0 0 500 500'><path d='M0,36.4C12.3,24.1,24.1,12.3,35.9,0.5C107.1,71.7,178.4,143,249.7,214.3c71.7-71.7,143-143,214-214c12.2,12.1,24,24,35.9,35.9c-71,71-142.3,142.3-213.6,213.6c71.5,71.5,142.8,142.8,214,214c-12.1,12.1-24,23.9-36,36c-71-71-142.4-142.4-213.7-213.7c-71.4,71.4-142.7,142.7-213.8,213.8c-12.1-12.1-23.9-23.9-35.7-35.7c70.9-70.9,142.3-142.3,213.4-213.4C142.6,179,71.2,107.6,0,36.4z'></path></svg>";
            a.appendChild(adiv);
            a.appendChild(adiv2);
            div.appendChild(a);

            var ul = document.createElement('ul');
            ul.className = "th__drop none-list-style";
            for(var i = 0 ; i < listItems.length ; i++) {
                var li = document.createElement('li');
                var a = document.createElement('a');
                a.href = "javascript:void(0)";
                if($(listItems[i]).hasClass('active')) {
                    a.className = "active";
                }
                a.innerText = $(listItems[i]).text();
                a.onclick = function(){
                    switchTab(this, $(ul).find('a').index($(this)));
                }
                li.appendChild(a);
                ul.appendChild(li);
            }
            div.appendChild(ul);

            $(div).insertAfter(mainList);

            let dropList = tabs.find('.th__drop');
            let moreArea = tabs.find('.th__more');
            let dmoreAreaOffsetLeft = moreArea.offset().left;
        });

        tabsResize();
    };

    tabsInit();
    $(window).on('resize', function () {
        tabsResize();
    });

    $('.tabs__toggle').on('click', function (e) {
        let ths = $(this);
        let dropMenu = ths.closest('.th__more').find('.th__drop');
        if (!ths.hasClass('activated')) {
            ths.addClass('activated');
            dropMenu.addClass('visible');
        } else {
            ths.removeClass('activated');
            dropMenu.removeClass('visible');
        }
        e.preventDefault();
    });
    function switchTab(el, index) {
        let ths = $(el);
        let headTabs = ths.closest('.tabs__head');
        let tabs = headTabs.closest('.tabs_block');
        if (ths.hasClass('active')) {
            return false;
        }

        let bodyTab = tabs.find('.tabs_texts');
        headTabs.find('.tabs__toggle').removeClass('activated');
        headTabs.find('.th__drop').removeClass('visible');

        headTabs.find('.active').removeClass('active');
        bodyTab.find('.active').removeClass('active');

        headTabs.find('li a').eq(index).addClass('active');
        headTabs.find('.tabs_name').eq(index).addClass('active');
        bodyTab.find('.tabs_text').eq(index).addClass('active');
    }
    $('.tabs__head a[data-tab]').on('click', function (e) {
        let ths = $(this);
        let thsAttr = ths.data('tab');
        if (ths.hasClass('active')) {
            return false;
        }
        let headTabs = ths.closest('.tabs__head');
        let tabs = headTabs.closest('.tabs');
        let bodyTab = tabs.find('.tabs__body');

        headTabs.find('.tabs__toggle').removeClass('activated');
        headTabs.find('.th__drop').removeClass('visible');

        headTabs.find('.active').removeClass('active');
        bodyTab.find('.visible').removeClass('visible');

        headTabs.find('a[data-tab=' + thsAttr + ']').addClass('active');
        bodyTab.find('.tab-one[data-tab=' + thsAttr + ']').addClass('visible');
        e.preventDefault();
    });
    $('.tabs__head .tabs_name').on('click', function (e) {
        let ths = $(this);
        let headTabs = ths.closest('.tabs__head');
        let tabs = headTabs.closest('.tabs_block');
        let index = $(tabs).find('.tabs__head .tabs_name').index(ths);
        switchTab(ths, index);
    });
};

const tipsFunctions = () => {
    function showTip(ths) {
        if (ths.hasClass('activated')) {
            //ths.find('.tip').remove();
            //ths.removeClass('activated');
        } else {
            let thsText = ths.data('desc');
            let thsImg = ths.data('img');
            let imgTag = '';
            ths.addClass('activated');
            if (thsImg) {
                imgTag = '<div class="tip__img"><img src="' + thsImg + '"></div>'
            }
            let closeBtn = $('<div class="tip__close"><svg class="icon"><use xlink:href="#close-icon"></use></svg></div>');
            let elem = $('<div class="tip">' + imgTag + '<div class="tip__text">' + thsText + '</div></div>');
            ths.append(elem);
            elem.append(closeBtn);
            $(closeBtn).on('click', function(){
                ths = $(this).closest('.tip-anchor');
                ths.find('.tip').remove();
                ths.removeClass('activated');
            });

            let elemOffsetLeft = elem.offset().left;
            if (elemOffsetLeft < 0) {
                elem.css({'margin-right': elemOffsetLeft - 19})
            }
        }
    }
    function hideTip(ths) {
        ths.find('.tip').remove();
        ths.removeClass('activated');
    }
    if(desktop) {
        $('.tip-anchor').on('mouseenter', function (e) {
            showTip($(this));
            e.preventDefault();
        });
        $('.tip-anchor').on('mouseleave', function (e) {
            hideTip($(this));
            e.preventDefault();
        });
    }
    else {
        $('.tip-anchor').on('click', function (e) {
            if ($(this).hasClass('activated')) {
                hideTip($(this));
            }
            else {
                showTip($(this));
            }
            e.preventDefault();
        });
    }
};

const langFunctions = () => {
    function closeLangPopup() {
        $('#select-region').removeClass('visible');
        enableScroll();
    }

    function showLangMenu() {
        $('.footer-lang').addClass('active');
        if(windowWidth < 1024) {
            disableScroll();
        }
    }

    function hideLangMenu() {
        $('.footer-lang').removeClass('active');
		if(windowWidth < 1024) {
        	enableScroll();
		}
    }

    $(window).on('resize', function () {
        if($('.footer-lang').hasClass('active')) {
            if(windowWidth < 1024) {
                disableScroll();
            }
            else {
                enableScroll();
            }
        }
    });

    $('.lang-toggle').on('click', function(e){
        if($('.footer-lang').hasClass('active')) {
            hideLangMenu();
        }
        else {
            showLangMenu();
        }
        e.stopPropagation();
    });
    $('.js-lang-menu-close').on('click', function(e){
        hideLangMenu();
        e.stopPropagation();
    });

    $(document).on('click', function (e) {
        if($('.footer-lang').hasClass('active') && !$(e.target.closest('.lang-menu-content')).length) {
            hideLangMenu();
        }
    });

    if($('#select-region').length) {
        if($('#select-region').hasClass('visible')) {
            disableScroll();
            $(document).on('click', function (e) {
                if($('#select-region').hasClass('visible') && !$(e.target.closest('.popup-window')).length) {
                    closeLangPopup();
                }
            });
            $('#select-region').find('.js-stay').on('click', function(){
                closeLangPopup();
            });
        }
    }


};

// const loadMoreNews = (block, moreBtn) => {
//     setTimeout(function () {
//         let elems = block.find('.row').children('div').clone();
//         elems.find('.news-one').addClass('animated-adding');
//         elems.appendTo(block.find('.row'));
//         showMore(moreBtn);
//     }, 3000)
// };
// const showMore = (btn) => {
//     btn.removeClass('disabled');
//     btn.find('.bar-block').removeClass('visible');
// };


function isMobile() {
    var check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}

function isTablet() {
    return (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
}

function isIE(userAgent) {
    userAgent = userAgent || navigator.userAgent;
    return userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;
}

const disableScroll =() => {
    stopHeaderChangesOnScroll = true;
    constWindowScrollTop = $(document).scrollTop();

    $('html').addClass('scroll-lock');
    $('.layout').css('margin-top',-constWindowScrollTop);
};

const enableScroll =() => {
    $('html').removeClass('scroll-lock');

    $('.layout').css('margin-top',0);

    $('html').scrollTop( constWindowScrollTop );
    stopHeaderChangesOnScroll = false;
};
